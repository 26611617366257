












import { Component, Vue } from "vue-property-decorator";
import TheHeader from "@/components/Header.vue";
import { SMRoot } from "./store";

@Component({
  components: {
    TheHeader,
  },
})
export default class App extends Vue {
  protected get views() {
    return SMRoot.views;
  }

  protected mounted() {
    //const fullPath = (this.$router as any).history._startLocation as string;
    SMRoot.startWatchingView();
    SMRoot.startWatchingViewport();
    //if (fullPath) {
    //  setTimeout(() => SMRoot.scrollTo(fullPath.replaceAll("/", "")), 200);
    //}
  }
}
