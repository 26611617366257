import { RouteConfig } from "vue-router";

class CView {
  name!: string;
  label!: string | null;

  constructor(name: CView["name"], label: CView["label"] = null) {
    this.name = name;
    this.label = label;
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const VTop = new CView("top");
const VServices = new CView("platform", "Платформа");
const VModules = new CView("module-platform");
const VContactUs = new CView("contact-us", "Будем на связи");
// const VMaps = new CView("maps");
const VDocs = new CView("doc", "Документы");
const VTests = new CView("tests", "Тестовая версия");
const VTariff = new CView("tariff", "Тарифы");

export const views: CView[] = [VTop, VServices, VModules, VDocs, VTests, VContactUs];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const components: { [key: string]: any } = {};
const component = (name: string) => () =>
  import(/* webpackChunkName: "[request]" */ "@/sections/" + name + ".vue");
views.forEach(({ name }) => (components[name] = component(name)));

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Layout",
    components,
    // children: [
    //   {
    //     path: "/platform",
    //   },
    //   {
    //     path: "/doc",
    //   },
    //   {
    //     path: "/tests",
    //   },
    //   {
    //     path: "/tariff",
    //   },
    // ],
  },

  {
    path: "*",
    redirect: "/",
  },
];

export default routes;
